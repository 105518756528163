import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

export default ({ alt }) => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "phone.preview.nb.png" }) {
          childImageSharp {
            fluid(maxWidth: 290, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <Img fluid={data.file.childImageSharp.fluid} alt={alt} />
      </div>
    )}
  />
);
