import './searching-wormie.css';
import React, { useEffect } from 'react';

const SearchingWormie = () => {
  useEffect(() => {
    setTimeout(
      () =>
        svgContainerRef.current &&
        svgContainerRef.current.classList.add('loaded'),
      800
    );
  });
  const svgContainerRef = React.useRef();

  return (
    <div ref={svgContainerRef} className="searching-wormie-container">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 664.29 1041.69">
        <ellipse
          cx="233.71"
          cy="970.08"
          rx="90.71"
          ry="69.11"
          className="w-cf-green-6 w-cs-green-4"
        />
        <ellipse
          cx="231.82"
          cy="915.12"
          rx="98.28"
          ry="74.88"
          className="w-cf-green-3 w-cs-green-4"
        />
        <ellipse
          cx="230.55"
          cy="834.19"
          rx="119.34"
          ry="90.93"
          className="w-cf-green-5 w-cs-green-4"
        />
        <ellipse
          cx="231.84"
          cy="749.46"
          rx="142.74"
          ry="102.63"
          className="w-cf-green-3 w-cs-green-4"
        />
        <ellipse
          cx="231.85"
          cy="661.29"
          rx="148.05"
          ry="106.45"
          className="w-cf-green-5 w-cs-green-4"
        />
        <ellipse
          cx="230.55"
          cy="550.23"
          rx="169.68"
          ry="122"
          className="w-cf-green-2 w-cs-green-4"
        />
        <path
          d="M412.16,431c7.52,72.68-70.61,137.59-169.12,144.5S62.71,528.73,55.41,456c-8.9-88.71,70.61-137.59,169.12-144.5S402.85,341,412.16,431Z"
          className="w-cf-green-6 w-cs-green-4"
        />
        <path
          d="M459.86,256.23C473.67,379,373.94,423.92,237.22,429.64,93.35,435.67,5.78,391.14,2.55,272.9.05,181.7,98.46,91.69,224.75,87.09S449.66,165.58,459.86,256.23Z"
          className="w-cf-green-2 w-cs-green-4"
        />
        <path
        className="w-cf-green-4"
          d="M145.34,125.92a7,7,0,0,1-3.58-3.77,194.91,194.91,0,0,0-55.1-77,7,7,0,0,1,9.1-10.69,208.77,208.77,0,0,1,59,82.46,7,7,0,0,1-9.46,9Z"
        />
        <circle cx="91.93" cy="38.53" r="23.4" className="w-cf-green-4" />
        <circle cx="145.89" cy="261.39" r="54.24" className="w-cf-white" />
        <circle cx="317.85" cy="260.83" r="54.24" className="w-cf-white"/>
        <path
          className="arm arm--left-second w-cf-green-4"
          d="M61.94,571.88c-9.34,0-24.39-1-38.36-6.63A14,14,0,0,1,34,539.17c15.37,6.14,34.07,4.41,34.25,4.4a14,14,0,0,1,2.83,27.93C70.71,571.54,67.24,571.88,61.94,571.88Z"
        />
        <path
          className="arm arm--left-first w-cf-green-4"
          d="M84.54,461.08c-19.16,0-52.58-11.67-63.41-18.71a14,14,0,0,1,15.31-23.54c8.65,5.62,40.92,15.47,49,14.15a14,14,0,1,1,4.46,27.72A34.34,34.34,0,0,1,84.54,461.08Z"
        />
        <ellipse
          cx="377.47"
          cy="332.25"
          rx="37.72"
          ry="21.62"
          transform="translate(-61.98 87.79) rotate(-12.27)"
          className="w-cf-yellow-1"
        />
        <ellipse
          cx="88.72"
          cy="332.25"
          rx="21.62"
          ry="37.72"
          transform="translate(-254.8 348.35) rotate(-77.73)"
          className="w-cf-yellow-1"
        />
        <path
        className="w-cf-green-1"
          d="M196.68,384.85c-18.57-4.38-34.87-12.3-46.36-22.9a7,7,0,1,1,9.52-10.32c15.4,14.21,41.44,22.83,69.67,23.05,29.1.24,56.59-8.77,71.74-23.5A7,7,0,1,1,311,361.24c-18,17.48-48.49,27.75-81.64,27.49A148.86,148.86,0,0,1,196.68,384.85Z"
        />
        <circle cx="318.58" cy="261.22" r="38.92" className="w-cf-green-6" />
        <circle cx="318.4" cy="260.81" r="35.14" className="w-cf-green-0" />
        <circle cx="297.23" cy="239.74" r="14.55" className="w-cf-white" />
        <circle cx="146.75" cy="262.51" r="38.92" className="w-cf-green-6" />
        <circle cx="146.58" cy="262.09" r="35.14" className="w-cf-green-0" />
        <circle cx="125.41" cy="241.03" r="14.55" className="w-cf-white" />
        <path
          d="M317.56,124.7a7,7,0,0,0,4.52-6.17A195,195,0,0,1,349.83,28a7,7,0,1,0-12-7.24,208.83,208.83,0,0,0-29.74,97,7,7,0,0,0,6.62,7.4A6.92,6.92,0,0,0,317.56,124.7Z"
          className="w-cf-green-4"
        />
        <circle cx="342.72" cy="23.4" r="23.4" className="w-cf-green-4" />
        <path
          d="M399.94,572c-5.31,0-8.77-.34-9.15-.38a14,14,0,0,1,2.84-27.93h0c.18,0,18.88,1.75,34.25-4.4a14,14,0,1,1,10.42,26.08C424.33,571,409.28,572,399.94,572Z"
          className="w-cf-green-4"
        />
        <path
          d="M401.76,470.67a34.34,34.34,0,0,1-5.39-.38,14,14,0,1,1,4.47-27.72c8.11,1.32,40.37-8.52,49-14.15A14,14,0,0,1,465.17,452C454.34,459,420.92,470.67,401.76,470.67Z"
          className="w-cf-green-4"
        />
        <path
          d="M406.14,654a24.49,24.49,0,0,1-7,.3,24.76,24.76,0,0,1-22.23-27l22.45-229.65a24.76,24.76,0,0,1,49.28,4.81L426.22,632.08A24.76,24.76,0,0,1,406.14,654Z"
          className="m-glass w-cf-blue-1"
        />
        <path
          d="M525.75,215.56c21.57,58.28,9.15,115.63-9.48,158.14-16.7,38.13-108.34,42.36-178.83-5.26-143.14-96.7-40.79-289,9.44-293C425.2,69.31,500.6,147.59,525.75,215.56Z"
          className="scaling-face-part w-cf-green-2 w-cs-green-4"
        />
        <ellipse
          cx="457.89"
          cy="346.91"
          rx="74.5"
          ry="42.7"
          className="scaling-face-part--cheek w-cf-yellow-1"
        />
        <circle
          cx="380.17"
          cy="235.69"
          r="75.35"
          className="scaling-face-part w-cf-white"
        />
        <circle
          cx="379.73"
          cy="235.05"
          r="54.07"
          className="scaling-face-part w-cf-green-6"
        />
        <circle
          cx="379.69"
          cy="234.81"
          r="48.82"
          className="scaling-face-part w-cf-green-0"
        />
        <circle
          cx="343.22"
          cy="214.46"
          r="20.22"
          className="scaling-face-part w-cf-white"
        />
        <path
          d="M412.94,39.51C308.86,51.21,234,145.07,245.68,249.15s105.57,179,209.64,167.26,179-105.56,167.26-209.64S517,27.81,412.94,39.51Zm37.48,333.26A145.72,145.72,0,1,1,578.94,211.68,145.73,145.73,0,0,1,450.42,372.77Z"
          className="m-glass w-cf-blue-1 w-cs-blue-2"
        />
        <path
          className="m-glass m-glass-shine"
          d="M497.12,121.78c27.89,16.41,38.27,38.33,42.07,53.49"
        />
        <circle
          cx="451.76"
          cy="453.84"
          r="28.79"
          className="m-glass w-cf-green-4"
        />
        <circle
          cx="432.52"
          cy="552.16"
          r="28.79"
          className="m-glass w-cf-green-4"
        />
      </svg>
    </div>
  );
};

export default SearchingWormie;
