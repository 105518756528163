import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import './about-game.css';

export default () => {
  const intl = useIntl();

  return (
    <section className="about">
      <h2>{intl.formatMessage({ id: 'aboutTheGame' })}</h2>
      <p>{intl.formatMessage({ id: 'moreAbout1' })}</p>
      <p>{intl.formatMessage({ id: 'moreAbout2' })}</p>
    </section>
  );
};
