import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import AboutGame from '../components/about-game/about-game';
import SearchingWormie from '../components/searching-wormie/searching-wormie';
import TabletPreviewImageEn from '../components/preview-images/tablet-en';
import PhonePreviewImageEn from '../components/preview-images/phone-en';
import TabletPreviewImageNo from '../components/preview-images/tablet-no';
import PhonePreviewImageNo from '../components/preview-images/phone-no';

import './index.css';

import AppStoreIconNo from '../images/svg/app-store.no.svg';
import GooglePlayNo from '../images/svg/google-play.no.svg';
import AppStoreIconEn from '../images/svg/app-store.en.svg';
import GooglePlayEn from '../images/svg/google-play.en.svg';
import StampNo from '../images/svg/stamp.no.svg';
import StampEn from '../images/svg/stamp.en.svg';

import { useIntl } from 'gatsby-plugin-intl';

const LocalizedAppStoreIcon = ({ locale }) =>
  locale === 'nb' ? (
    <AppStoreIconNo style={{ width: '100%' }} />
  ) : (
    <AppStoreIconEn style={{ width: '100%' }} />
  );

const LocalizedGooglePlayIcon = ({ locale }) =>
  locale === 'nb' ? (
    <GooglePlayNo style={{ width: '100%' }} />
  ) : (
    <GooglePlayEn style={{ width: '100%' }} />
  );

const localeMap = {
  nb: 'no',
  en: 'en',
};
const StoreIconWithLink = ({ appId, storeType, title, locale, isTablet }) => (
  <div className="store-link-container">
    {storeType === 'apple' ? (
      <a
        title={title}
        className="apple-link"
        href={`https://apps.apple.com/${
          locale === 'nb' ? 'no' : 'us'
        }/app/${appId}?l=${localeMap[locale]}${isTablet ? '#?platform=ipad' : ''}`}
      >
        <LocalizedAppStoreIcon locale={locale} />
      </a>
    ) : (
      <a
        title={title}
        href={`https://play.google.com/store/apps/details?id=${appId}`}
      >
        <LocalizedGooglePlayIcon locale={locale} />
      </a>
    )}
  </div>
);

const Letter = ({ color, children }) => (
  <span style={{ color: color }}>{children}</span>
);

const colors = {
  lightBlue: '#2490f2',
  orange: '#fb8d0c',
  green: '#42a87e',
  red: '#ef5025',
};

const colorsValues = Object.values(colors);

const randomColor = () =>
  colorsValues[Math.floor(Math.random() * colorsValues.length)];

const colorize = text =>
  text.split('').reduce((aggregated, letter, index) => {
    let result;
    if (index === 0) {
      result = [letter, randomColor()];
    } else if (letter === ' ') {
      result = [letter, aggregated[index - 1]];
    } else {
      let color;
      do {
        color = randomColor();
      } while (color === aggregated[index - 1][1]);
      result = [letter, color];
    }
    return aggregated.concat([result]);
  }, []);

const IndexPage = () => {
  const intl = useIntl();
  const titleColored = colorize(intl.formatMessage({ id: 'gameName' }));

  const stamp =
    intl.locale === 'nb' ? (
      <StampNo className="stamp"></StampNo>
    ) : (
      <StampEn className="stamp"></StampEn>
    );

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'gameName' })} />
      <div className="page-wrapper">
        <div className="promo-container">
          {stamp}
          <div className="promo">
            <header className="promo-header">
              <SearchingWormie />
              <h1 className="title">
                <div>{intl.formatMessage({ id: 'findWordsWith' })}</div>
                <div className="colored-title">
                  {titleColored.map(([letter, color], index) => (
                    <Letter key={index} color={color}>
                      {letter}
                    </Letter>
                  ))}
                </div>
              </h1>
            </header>
            <div className="promo-body">
              <div className="promo-text">
                {intl.formatMessage({ id: 'promoText' })}
              </div>
              <div className="promo-body--inner">
                <div className="store-container store-container--phone">
                  <h2 className="store-container-heading">
                    {intl.formatMessage({ id: 'forPhone' })}
                  </h2>
                  <p className="review">
                    "{intl.formatMessage({ id: 'review3' })}"
                  </p>
                  <p className="review">
                    "{intl.formatMessage({ id: 'review1' })}"
                  </p>
                  <p className="review">
                    "{intl.formatMessage({ id: 'review2' })}"
                  </p>
                  <div className="phone-spacer"></div>
                  <div className="image-mockup-container">
                    {intl.locale === 'nb' ? (
                      <PhonePreviewImageNo alt="Spill vises på en telefon" />
                    ) : (
                      <PhonePreviewImageEn alt="Game displaying on a phone" />
                    )}
                  </div>
                  <p>{intl.formatMessage({ id: 'free' })}!</p>
                  <div className="store-links">
                    <h2 className="store-container-heading">
                      {intl.formatMessage({ id: 'forPhone' })}
                    </h2>
                    <p className="tablet-version-reference">
                      (
                      {intl.formatMessage({
                        id: 'tabletVersionReference',
                      })}
                      )
                    </p>
                    <StoreIconWithLink
                      appId="wormie-ordsøk/id1502900175"
                      storeType="apple"
                      title={intl.formatMessage({
                        id: 'appStoreTitleForPhone',
                      })}
                      locale={intl.locale}
                    />
                    <StoreIconWithLink
                      appId="no.wormie.app"
                      storeType="google"
                      title={intl.formatMessage({
                        id: 'googlePlayTitleForPhone',
                      })}
                      locale={intl.locale}
                    />
                  </div>
                </div>
                <div className="store-container store-container--tablet">
                  <h2 className="store-container-heading">
                    {intl.formatMessage({ id: 'forTablet' })}
                  </h2>
                  <div className="image-mockup-container image-mockup-container--tablet">
                    {/* {isEasterCampaignActive && (
                      <p className="no-margin easter-offer-label easter-offer-bg">
                        {intl.formatMessage({ id: 'easterOffer' })}!
                      </p>
                    )} */}
                    {intl.locale === 'nb' ? (
                      <TabletPreviewImageNo alt="Spill vises på et nettbrett" />
                    ) : (
                      <TabletPreviewImageEn alt="Game displaying on tablet" />
                    )}
                  </div>
                  {/* {isEasterCampaignActive ? (
                    <div className="easter-offer-container">
                      <div className="easter-offer easter-offer-bg">

                        {intl.formatMessage({ id: 'freeDuringEaster' })}!
                      </div>
                      <div className="easter-offer-regular-price">
                        (
                        {intl.formatMessage(
                          { id: 'regularPrice' },
                          { price: getTabletVersionPrice() }
                          )}
                        )
                      </div>
                    </div>
                  ) : (
                    <p>{getTabletVersionPrice()}</p>
                    )} */}
                  <p>{intl.formatMessage({ id: 'free' })}!</p>
                  <div className="store-links">
                    <h2 className="store-container-heading">
                      {intl.formatMessage({ id: 'forTablet' })}
                    </h2>
                    <StoreIconWithLink
                      appId="wormie-ordsøk/id1502900175"
                      storeType="apple"
                      isTablet={true}
                      title={intl.formatMessage({
                        id: 'appStoreTitleForTablets',
                      })}
                      locale={intl.locale}
                    />
                    <StoreIconWithLink
                      appId="no.wormie.app"
                      storeType="google"
                      title={intl.formatMessage({
                        id: 'googlePlayTitleForTablets',
                      })}
                      locale={intl.locale}
                    />
                  </div>
                </div>
              </div>
              <AboutGame></AboutGame>
              <footer>
                <div className="footer-link">
                  <a
                    href="mailto:support@wormie.app?subject=Wormie"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage({ id: 'pleaseGetInTouch' })}
                  </a>
                </div>
                <div className="footer-link">
                  <a
                    href="https://fb.me/wormie.word.search"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage({ id: 'findUsOnFacebook' })}
                  </a>
                </div>
                <div className="footer-link">
                  <Link to={intl.locale === 'en' ? '/' : '/en'}>
                    {intl.locale === 'en' ? 'Norsk versjon' : 'English version'}
                  </Link>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
